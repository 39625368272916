<template>
  <div>
    <!-- 导航栏-->
    <Nav nav="aboutUs"></Nav>
    <!--    菜单-->
    <sideMenu></sideMenu>
    <!--  内容-->
    <div class="container-box">
      <!--    banner-->
      <div class="banner-box">
        <img src="https://file.xssbdc.com/static/website/banner4.png" alt="">
        <div>
          <span class="banner-text-title">关于我们</span>
          <span class="banner-text-title-en">ABOUT US</span>
        </div>

      </div>
      <!--    公司信息-->

      <div class="gs-info">
        <div class="container">
          <div class="row">
            <div class="info-item col-md-4 col-sm-12">
              <div class="info-item-tit">公司地址</div>
              <div class="didian">
                <img src="https://file.xssbdc.com/static/website/dizi.png" class="dizi" alt="">
                <span>地点</span>
              </div>
              <hr>
              <div class="address">重庆市江北区建新北路二支路19号附10号</div>
            </div>
            <div class="info-item col-md-4 col-sm-12">
              <div class="info-item-tit">联系方式</div>
              <div class="didian">
                <img src="https://file.xssbdc.com/static/website/dianhua.png" class="dizi" alt="">
                <span>电话</span>
              </div>
              <hr>
              <div class="address">023-67456888</div>
              <div class="address"> 7*24小时服务：17323598502</div>
            </div>
            <div class="info-item col-md-4 col-sm-12">
              <div class="info-item-tit">市场合作</div>
              <div class="didian">
                <img src="https://file.xssbdc.com/static/website/youxiang.png" class="dizi" alt="">
                <span>邮箱</span>
              </div>
              <hr>
              <div class="address">3094880239@qq.com</div>
            </div>
          </div>
        </div>
      </div>
      <!--    简介-->
      <div class="Introduction">
        <img src="https://file.xssbdc.com/static/website/bg2.png" class="bg2" alt="">
        <div class="Introduction-box container">
          <div class="Introduction-info">
            <div class="gs-name">德润消费大数据科技（重庆）有限公司</div>
            <div class="line"></div>
            <div class="gs-info-text">德润消费大数据科技（重庆）有限公司（简称：德润消费大数据）
              成立于2018年5月28日，注册资本人民币500万元。德润消费大数据（重庆）有限公司是一家以计算机系统集成；数据处理；网站建设；软件开发；大数据系统技术开发、技术咨询、技术转让、技术
              服务为核心，专业致力于网站开发、计算机软件开发及应用的科技型公司，隶属于重庆新盛世商业管理集团有限公司。从组建、成立、发展至今，已拥有一批长期从事软件开发、技术型人才，具有雄厚的技术开发实力，全方位为公司业务提供技术支持，满足公司的信息化建设需求。
            </div>
            <div class="gs-info-text">
              在强力的市场竞争和需求下，公司愿以最敏锐的洞察力、最高效的沟通力、最稳健的控制力和最先行的执行力、整合一切可利用资源帮助客户实现金融助贷愿景和目标、与互联网信息化服务全方位接轨。公司以推动大数据整合为己任，凭借对"专业、创新、诚信、务实、高效"的不断地追求，致力于传统流程和大数据分析的结合，努力建设成为数据完备
              、技术领先、专业、权威的大数据金融企业,助力于小微企业、银行机构、个人助贷的金融服务与合作。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--      脚标-->
    <tfooter></tfooter>
  </div>
</template>

<script>
import Nav from '../components/nav';
import sideMenu from '../components/side-menu';
import tfooter from "@/components/footer";

export default {
  name: "about-us",
  components: {Nav, sideMenu, tfooter},
}
</script>

<style scoped lang="less">
.banner-box {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .banner-text-title {
      font-size: 76px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 66px;
    }

    .banner-text-title-en {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

}

//公司信息
.gs-info {
  background-color: #fff;
  width: 100%;
  padding: 150px 0 ;
  //.container{
  //  width: 1400px;
  //}
}

.info-item {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 28px ;

  .info-item-tit {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-bottom: 48px;
  }

  .didian {
    display: flex;
    align-items: center;
    margin-bottom: 23px;

    .dizi {
      width: 26px;
      height: 26px;
      margin-right: 16px;
    }

    span {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }

  hr {
    width: 100%;
    color: #D1D7DE;
    margin-bottom: 26px;
  }

  .address {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-bottom: 22px;
  }
}

//简介
.Introduction {
  background-color: #F8F8F8;
  width: 100%;
  position: relative;

  .bg2 {
    position: absolute;
    top: 10%;
    right: 0;
    width: 73%;
    height: 70%;
    z-index: 1;
  }

  .Introduction-box {
    //padding: 460px 360px 200px 260px;
    padding: 460px 0 200px ;
    .Introduction-info {
      width: 100%;
      background-color: #fff;
      position: relative;
      z-index: 2;
      padding: 15% 10%;

      .gs-name {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 30px;
      }

      .line {
        width: 40px;
        height: 4px;
        background: #333333;
        margin-bottom: 93px;
      }

      .gs-info-text {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        margin-bottom: 85px;
      }
    }
  }
}

</style>